import {defineStore} from 'pinia';
import {DataService} from '../../api/data/DataService';
import {HitUUIDUtils} from '@hit/components';
import {actionToCard, sortActions} from 'hit-online-web-ui/src/helpers/action';
import {useConfigurationStore} from './configuration';
import {useUserProfileStore} from './userProfile';
import {HitActionUtils} from '@hit/components/src/utils/action/HitActionUtils';
import {ActionService} from '../../api';

export const useActionStore = defineStore('action', {
  state: () => ({
    assignedCount: null,
    assignedActions: [],
    activeActionId: null,
    actionStack: [],
    elapsedTime: 0,
    chronometerInterval: null,
    userStaffId: null,
    actionFilters: null,
    changingState: false,
  }),
  getters: {
    /**
     * When the changing state is set to true, we display spinners instead of play
     * icons to disable a second click while the changes are done in Action API
     */
    getChangingState(state) {
      return state.changingState;
    },
    activeCard(state) {
      return state.assignedActions.find(
        (action) => action.id === state.activeActionId
      );
    },
    activeActionIsRunning() {
      return this.activeCard?.running_state === 'START' ?? false;
    },
    formattedElapsedTime(state) {
      const hours = Math.floor(state.elapsedTime / (1000 * 60 * 60));
      const minutes = Math.floor(
        (state.elapsedTime % (1000 * 60 * 60)) /
          (1000 * 60).toString().padStart(2, '0')
      );
      const seconds = Math.floor((state.elapsedTime % (1000 * 60)) / 1000)
        .toString()
        .padStart(2, '0');
      return `${hours}h ${minutes}m ${seconds}s`;
    },
    actionsInStack(state) {
      const stack = [];
      state.actionStack.forEach((actionId) => {
        stack.push(
          state.assignedActions.find((action) => action.id === actionId)
        );
      });
      return stack;
    },
  },

  actions: {
    /**
     * Init method executed during mounting process of entire application
     */
    async initActionStore() {
      await this.updateAssignedActions();
    },

    /**
     * Returns the action object with the passed ID
     */
    getAction(actionId) {
      return this.assignedActions.find((action) => action.id === actionId);
    },

    /**
     * Checks if the passed actionId is an action assigned to us
     */
    isMyAction(actionId) {
      return this.assignedActions.some((obj) => obj.id === actionId);
    },

    /**
     * Assigns the action via the Action API
     */
    async assignAction(actionId, assigneeId) {
      return ActionService.post('hit-action/assign', {
        values: {
          action_id: actionId,
          assignee_id: assigneeId,
          assigner_id: useUserProfileStore().staffId,
        },
      });
    },

    /**
     * Unassigns the action via the Action API to make some checks first
     */
    async unAssignAction(actionId) {
      return ActionService.post(`hit-action/unassign/${actionId}`)
        .then((res) => {
          this.updateAssignedActions();
          return res;
        })
        .catch((e) => {
          console.error('Error while unassigning action');
        });
    },

    /**
     * Starts the action via the Action API to make different checks
     */
    async startAction(actionId) {
      this.changingState = true;
      this.activeActionId = actionId;
      return ActionService.post(`hit-action/start/${actionId}`)
        .then(() => {
          this.updateAssignedActions();
        })
        .catch((e) => {
          console.error(`Error while starting action, ${e}`);
        });
    },

    /**
     * Pauses the action via the Action API to make different checks
     */
    async pauseAction(actionId) {
      this.changingState = true;
      return ActionService.post(`hit-action/pause/${actionId}`)
        .then(() => {
          this.updateAssignedActions();
        })
        .catch((e) => {
          console.error(`Error while pausing action, ${e}`);
        });
    },

    /**
     * Stops the action via the Action API to make different checks
     */
    async stopAction(actionId) {
      this.changingState = true;
      return ActionService.post(`hit-action/stop/${actionId}`)
        .then(() => {
          this.updateAssignedActions();
        })
        .catch((e) => {
          console.error(`Error while stopping action, ${e}`);
        });
    },

    /**
     * Deletes the action via the Action API to make some checks before deleting it
     */
    async deleteAction(actionId) {
      return ActionService.post('hit-action/delete', {
        values: {actions_to_delete: [actionId]},
      })
        .then(() => {
          this.updateAssignedActions();
        })
        .catch((e) => {
          console.error(`Error while deleting action, ${e}`);
        });
    },

    /**
     * Closes the action via the Action API to make some checks before closing it
     */
    async closeAction(actionId) {
      return ActionService.post(`hit-action/close/${actionId}`)
        .then(() => {
          this.updateAssignedActions();
        })
        .catch((e) => {
          console.error(`Error while closing action, ${e}`);
        });
    },

    /**
     * Reopens the action via the Action API to make some checks before reopening it
     */
    async reopenAction(actionId) {
      return ActionService.post(`hit-action/reopen/${actionId}`)
        .then(() => {
          this.updateAssignedActions();
        })
        .catch((e) => {
          console.error(`Error while reopening action, ${e}`);
        });
    },

    /**
     * Adds the tag to the action and registers the operation in the history table
     */
    async addActionTag(actionId, tagId) {
      await Promise.all([
        DataService.create('action_tag', {
          action_id: actionId,
          tag_id: tagId,
        }),
        DataService.create('action_history', {
          id: HitUUIDUtils.generate(),
          action_id: actionId,
          operation: HitActionUtils.Operation.ADD_TAG,
          tag_id: tagId,
        }),
      ]);
      return this.updateAssignedActions();
    },

    /**
     * Removes the tag from the action and registers this operation in the history table
     */
    async removeActionTag(actionId, tagId) {
      await Promise.all([
        DataService.delete('action_tag', {
          action_id: `eq.${actionId}`,
          tag_id: `eq.${tagId}`,
        }),
        DataService.create('action_history', {
          id: HitUUIDUtils.generate(),
          action_id: actionId,
          operation: HitActionUtils.Operation.REMOVE_TAG,
          tag_id: tagId,
        }),
      ]);
      return this.updateAssignedActions();
    },

    /**
     * Adds a comment to the action history
     */
    async comment(actionId, comment) {
      return DataService.create('action_history', {
        id: HitUUIDUtils.generate(),
        action_id: actionId,
        message: comment,
        operation: HitActionUtils.Operation.MESSAGE,
      });
    },

    /**
     * Renames the action in the database
     */
    async renameAction(actionId, newName, updateAction = true) {
      const promises = [];
      if (updateAction) {
        promises.push(
          DataService.update(
            'action',
            {id: `eq.${actionId}`},
            {designation: newName}
          )
        );
      }
      promises.push(
        DataService.create('action_history', {
          id: HitUUIDUtils.generate(),
          action_id: actionId,
          message: newName,
          operation: HitActionUtils.Operation.RENAME,
        })
      );
      if (this.isMyAction(actionId)) {
        await Promise.all(promises);
        return this.updateAssignedActions();
      } else {
        return Promise.all(promises);
      }
    },

    /**
     * Stores the last used filters otherwise they would disappear when the user changes the view
     */
    setActionFilter(filterString) {
      this.actionFilters = filterString;
      this.updateAssignedActions();
    },

    /**
     * Calculates the total amount of assigned actions of the user
     */
    getCountOfAssignedActions(staffId) {
      if (!staffId) return;
      DataService.read('action', {
        attributes: 'count()',
        filters: {
          assignee_id: `eq.${staffId}`,
          status: `eq.ASSIGNED`,
        },
      }).then((response) => {
        if (response && response.data && response.data.length > 0) {
          this.assignedCount = response.data[0].count;
        }
      });
    },

    /**
     * Starts the timer in the header
     */
    startChronometer() {
      this.setConsumedDuration();
      this.stopChronometer();
      this.chronometerInterval = setInterval(() => {
        if (this.activeCard) {
          this.activeCard.finished_duration += 1000;
          this.elapsedTime += 1000;
        }
      }, 1000);
    },

    /**
     * Stops the counter in the header
     */
    stopChronometer() {
      clearInterval(this.chronometerInterval);
    },

    /**
     * Initialises the timer in the header with the previously consumed time
     */
    setConsumedDuration() {
      let timeSinceLastStart = 0;
      if (this.activeCard.last_start) {
        timeSinceLastStart =
          new Date().getTime() - this.activeCard.last_start.getTime();
      }
      this.elapsedTime = timeSinceLastStart + this.activeCard.finished_duration;
    },

    /**
     * Updates the right column with the state of the assigned actions and also
     * updates the top bar with the running action because it is the same store
     */
    async updateAssignedActions() {
      this.stopChronometer();
      const staffId = useUserProfileStore().staffId;
      this.getCountOfAssignedActions(staffId);
      const filters = {
        assignee_id: `eq.${staffId}`,
        status: 'eq.ASSIGNED',
      };
      if (this.actionFilters) {
        const filterParts = this.actionFilters
          .split(' ')
          .filter((i) => i !== '');
        let designationString = '';
        filterParts.forEach((filter) => {
          designationString += `designation.ilike.*${filter}*,`;
        });
        filters['tagFilter.and'] = `(${designationString.slice(0, -1)})`;
        filters['addressFilter.and'] = `(${designationString.slice(0, -1)})`;
        filters['staffFilter.and'] = `(${designationString.slice(0, -1)})`;
        filters['equipmentFilter.and'] = `(${designationString.slice(0, -1)})`;
        filters['projectPartFilter.and'] = `(${designationString.slice(
          0,
          -1
        )})`;
        const resourceString = designationString.replaceAll(
          'designation',
          `designation_${useConfigurationStore().userLanguageSnakeCase}`
        );
        const noString = designationString.replaceAll('designation', 'no');
        filters['resourceFilter.and'] = `(${resourceString.slice(0, -1)})`;
        filters['or'] = `(and(${designationString.slice(
          0,
          -1
        )}),and(${noString.slice(
          0,
          -1
        )}),tagFilter.not.is.null,addressFilter.not.is.null,staffFilter.not.is.null,resourceFilter.not.is.null,equipmentFilter.not.is.null,projectPartFilter.not.is.null)`;
      }
      const response = await DataService.read('action', {
        attributes: `id, status, no, designation, assignee_id!inner(id, user_id, first_name, last_name), creation_user_id, due_date, urgency, url, modification_date, importance,description,address_id,staff_id,equipment_id,project_part_id,document_id,resource_id,form_id,cost_id, sort_order, tag(id,designation,colour), action_history(operation, duration, operation_date),address(no,designation),staff!fk_action_staff_id_staff(no, designation),equipment(no,designation),resource(no,designation_${
          useConfigurationStore().userLanguageSnakeCase
        }),project_part(no,designation),tagFilter:tag(),addressFilter:address(),staffFilter:staff!fk_action_staff_id_staff(),resourceFilter:resource(),equipmentFilter:equipment(),projectPartFilter:project_part()`,
        order: 'sort_order.desc.nullsfirst',
        filters: filters,
        limit: 30,
      });
      this.activeActionId = null;
      this.assignedActions = [];
      if (response && response.data && response.data.length > 0) {
        const actionCards = response.data.map((action) => {
          let totalDuration = 0;
          action.action_history.forEach((op) => {
            totalDuration += HitActionUtils.durationToMs(op.duration);
          });

          const lastOperation = action.action_history.reduce((max, current) => {
            if (['START', 'PAUSE', 'STOP'].includes(current.operation)) {
              return new Date(current.operation_date) >
                new Date(max.operation_date)
                ? current
                : max;
            } else {
              return max;
            }
          });
          const userLan = useConfigurationStore().userLanguageSnakeCase;
          if (lastOperation.operation === 'START') {
            return actionToCard(
              action,
              totalDuration,
              'START',
              new Date(lastOperation.operation_date),
              userLan
            );
          } else if (lastOperation.operation === 'PAUSE') {
            return actionToCard(action, totalDuration, 'PAUSE', null, userLan);
          } else {
            return actionToCard(action, totalDuration, 'STOP', null, userLan);
          }
        });

        const sortedActions = sortActions('status', actionCards);
        this.assignedActions = sortedActions?.assigned ?? [];
        this.actionStack = [];
        let activeActionIfNoStart = null;
        this.assignedActions.forEach((action) => {
          if (action.running_state === 'START') {
            this.activeActionId = action.id;
            this.startChronometer();
          }
          if (['START', 'PAUSE'].includes(action.running_state)) {
            activeActionIfNoStart = action;
            this.actionStack.push(action.id);
          }
        });
        if (!this.activeActionId && activeActionIfNoStart) {
          this.activeActionId = activeActionIfNoStart.id;
          this.setConsumedDuration();
        }
      }
      this.changingState = false;
    },
  },
});
